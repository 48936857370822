import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PageWithHeader from '../components/pageWithHeader';
import { format } from 'date-fns';
// @ts-ignore
import facebook from '../../static/social/f_logo_RGB-White_58.png';
import style from './insights-legacy.module.scss';
import { TertiaryLinkButton } from '../components/buttons';

const Insights = ({ data }) => {
  const insights = data.insights.edges
    .filter(i => i.node.category === 'Insight')
    .map(i => i.node)
    .sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());

  const image = i => {
    return i.image
      ? i.image.localFile.childImageSharp.fluid
      : data.defaultImage.edges[0].node.childImageSharp.fluid;
  };
  
  const DivWithBackground = ({ fluid, children }: any) => (
    <div className={style.imageParentContainer}>
      <Img className={style.imageContainerImage} fluid={fluid} />
      <div className={style.imageContainerContent}>
        {children}
      </div>
    </div>
  );

  const formatDate = date => format(new Date(date), 'MMM d yyyy');

  return (
    <PageWithHeader title={'Insights'}>
      <article className={style.article}>
        {insights.map(i => (
          <section className={style.insightCard} key={i.id}>
            <DivWithBackground fluid={image(i)}>
              <div className={style.titleBox}>
                <div>
                  <h2 className={style.insightName}>{i.title}</h2>
                  <div className={style.byLine}>
                    By {i.insight_author},{' '}
                    <time dateTime={i.dateCreated}>
                      {formatDate(i.dateCreated)}
                    </time>
                  </div>
                </div>
                <div className={style.hoverContent}>
                  <div className={style.shareBox}>
                    <div>Share: </div>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.facebook.com/centeva.8/"
                        >
                          <img src={facebook} alt="Tag us on Facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://twitter.com/centeva"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <title>Follow us on Twitter</title>
                            <path d="M512 97.209c-18.838 8.354-39.082 14.001-60.33 16.54 21.686-13 38.343-33.585 46.186-58.115-20.298 12.039-42.778 20.78-66.705 25.49-19.16-20.415-46.461-33.17-76.673-33.17-58.011 0-105.044 47.029-105.044 105.039 0 8.233.929 16.25 2.72 23.939-87.3-4.382-164.701-46.2-216.509-109.753-9.042 15.514-14.223 33.558-14.223 52.809 0 36.444 18.544 68.596 46.73 87.433-17.219-.546-33.416-5.271-47.577-13.139-.01.438-.01.878-.01 1.321 0 50.894 36.209 93.348 84.261 103-8.813 2.4-18.094 3.686-27.674 3.686-6.769 0-13.349-.66-19.764-1.886 13.368 41.73 52.16 72.103 98.126 72.948-35.95 28.175-81.243 44.967-130.458 44.967-8.479 0-16.84-.497-25.058-1.47 46.486 29.805 101.701 47.197 161.021 47.197 193.211 0 298.868-160.062 298.868-298.872 0-4.554-.103-9.084-.305-13.59 20.528-14.81 38.336-33.31 52.418-54.374z"></path>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.linkedin.com/company/995615/"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <title>Find us on LinkedIn</title>
                            <path d="M80.111 25.6c-29.028 0-48.023 20.547-48.023 47.545 0 26.424 18.459 47.584 46.893 47.584h.573c29.601 0 47.999-21.16 47.999-47.584-.543-26.998-18.398-47.545-47.442-47.545zm-48.111 128h96v320.99h-96v-320.99zm323.631-7.822c-58.274 0-84.318 32.947-98.883 55.996v1.094h-.726c.211-.357.485-.713.726-1.094v-48.031h-96.748c1.477 31.819 0 320.847 0 320.847h96.748v-171.241c0-10.129.742-20.207 3.633-27.468 7.928-20.224 25.965-41.185 56.305-41.185 39.705 0 67.576 31.057 67.576 76.611v163.283h97.717v-176.313c0-104.053-54.123-152.499-126.347-152.499z"></path>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </DivWithBackground>
            <div className={style.descriptionText}>
              <p>{i.description.description}</p>
              <TertiaryLinkButton to={`/article/${i.slug}`}>Read</TertiaryLinkButton>
            </div>
          </section>
        ))}
      </article>
    </PageWithHeader>
  );
};

export default Insights;

export const query = graphql`
  query InsightQuery{
    insights: allContentfulInsight(filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          id
          category
          title
          slug
          dateCreated
          insight_author
          description {
            description
          }
          image {
            localFile {
              url
              childImageSharp {
                fluid {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
          }
        }
      }
    }
    allContentfulAsset {
      edges {
        node {
          fluid {
            src
          }
          description
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    defaultImage: allFile(filter: { name: { eq: "centeva-banner" } }) {
      edges {
        node {
          id
          url
          name
          childImageSharp {
            fluid {
              src
              srcSet
              aspectRatio
              sizes
            }
          }
        }
      }
    }
  }
`;
